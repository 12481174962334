@import 'variables';

.cart-header {
    margin-top: 1.8rem;
    margin-bottom: 0;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: $darkgray;
    text-shadow: 0 3px 10px rgba(65, 65, 65, 0.5);
    font-size: 2rem;
}

.cart-container {
  padding: 2rem;

  .cart-item {
    margin-left: auto;
    margin-right: auto;
    max-width: 550px;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: $white;
    box-shadow: 0 7px 10px rgba(65, 65, 65, 0.5);
    transition: all 0.3s ease-in-out;
    margin-top: 2.5rem;

    .cart-item-image {
        width: 250px;
        height: 250px;
        object-fit: contain;
        margin-right: 1rem;

        @media (max-width: 500px) {
          width: 170px;
          height: 170px;
        }
    }

    .cart-item-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;

      .cart-item-name {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        margin-top: 0;
        font-family: 'Montserrat', sans-serif;
      }

      .cart-item-size {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        margin-top: 0;
        font-family: 'Montserrat', sans-serif;
      }

      .cart-item-quantity-control {
        display: flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;

        .button-add {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          margin-left: 0.5rem;
          background-color: $black;
          color: $white;
          border: none;
          border-radius: 10PX;
          cursor: pointer;
          font-size: 1.5rem;
          font-family: 'Montserrat', sans-serif;

          &.disabled {
            background-color: $gray;
            cursor: not-allowed;
          }

          &:hover {
            background-color: $lightgray;
            color: $black;
            transform: scale(1.1);
            box-shadow: $shadow;
            transition: all 0.3s ease-in-out;
          }
        }
        .button-subtract {
          padding-left: 0.65rem;
          padding-right: 0.65rem;
          margin-right: 0.5rem;
          background-color: $black;
          color: $white;
          border: none;
          border-radius: 10PX;
          cursor: pointer;
          font-size: 1.5rem;
          font-family: 'Montserrat', sans-serif;

          &.disabled {
            background-color: $gray;
            cursor: not-allowed;
          }

          &:hover {
            background-color: $lightgray;
            color: $black;
            transform: scale(1.1);
            box-shadow: $shadow;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      .cart-item-price {
        margin-top: 1rem;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2rem;
      }

      .cart-item-remove {
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        background-color: black;
        color: $white;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;

        &.disabled {
            background-color: $gray;
            cursor: not-allowed;
          }

          &:hover {
            background-color: $lightgray;
            color: $black;
            transform: scale(1.1);
            box-shadow: $shadow;
            transition: all 0.3s ease-in-out;
          }
      }
    }
  }
}

.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0 7px 10px rgba(65, 65, 65, 0.5);
  transition: all 0.3s ease-in-out;
  margin-top: 70px;
  max-width: 300px;

  .cart-total {
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      margin: 2rem;
      font-size: 1.2rem;
  }

  .cart-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .continue-shopping-button, .checkout-button {
        font-family: 'Montserrat', sans-serif;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        border: none;
        background-color: $black;
        color: $white;
        text-decoration: none;
        text-align: center;
        font-size: 1rem;
        margin-bottom: 2rem;
        

        &.disabled {
            background-color: $gray;
            cursor: not-allowed;
          }

          &:hover {
            background-color: $lightgray;
            color: $black;
            transform: scale(1.1);
            box-shadow: $shadow;
            transition: all 0.3s ease-in-out;
          }
    }
  }
}



