@import 'variables';

@media (min-width: 929px) {
  .product-container {
    display: flex;
  }

  .product-image-container {
    margin-left: 70px;
    flex: 1;
  }

  .product-detail-container {
    margin-right: 70px;
    flex: 1;
  }
}

@media (max-width: 929px) {

  .product-detail-container {
    margin-bottom: 70px;
  }

  .return-button {
    align-items: center;
  }
}

.product-image-container {
  align-items: center;
  margin-top: 40px;

  .product-box {
    display: flex;
    margin: 1rem;
    background-color: $white;
    border-radius: 10px;
    box-shadow: $shadow;

    .left-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
    }

    .left-section-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2rem;
    }

    .left-section {
      border-radius: 10px 0 0 10px;
      overflow: hidden;

      .product-image {
        width: 100%;
        height: auto;
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        &.magnified {
          cursor: zoom-in;
        }

        .product-slider {
          display: flex;
          overflow-x: auto;
          width: 100%;
          height: 100%;
          scroll-snap-type: x mandatory;

          &::-webkit-scrollbar {
            height: 3px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #999;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #666;
          }

          img {
            flex: 0 0 auto;
            width: 100%;
            height: auto;
            scroll-snap-align: start;
          }
        }
      }
    }
  }
}

.product-detail-container {
  align-items: center;
  margin-top: 40px;

  .product-box {
    display: flex;
    margin: 1rem;
    background-color: $white;
    border-radius: 10px;
    box-shadow: $shadow;

    .right-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
    }

    .right-section-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;
    }

    .right-section {
      border-radius: 0 10px 10px 0;

      .product-details {
        padding: 1rem;

        .product-info {
          margin-bottom: 1rem;

          .product-name {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            font-family: 'Montserrat', sans-serif;
            text-align: center;
          }

          .product-price {
            font-size: 1.2rem;
            margin-bottom: 2rem;
            font-family: 'Montserrat', sans-serif;
            text-align: center;
          }

          .product-description {
            margin-bottom: 2rem;
            font-family: 'Montserrat', sans-serif;
            text-align: center;
          }
        }

        .product-sizes {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 2rem;

          .size-button {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            border: 1px solid $gray;
            border-radius: 5px;
            background-color: transparent;
            color: $black;
            cursor: pointer;
            transition: 0.3s;
            font-family: 'Montserrat', sans-serif;

            &.selected {
              background-color:$black;
              color: $white;
            }

            &:not(:last-child) {
              margin-right: 0.5rem;
            }

            &:hover {
              background-color: $gray;
              color: $white;
              transform: scale(1.1);
            }
          }
        }

        .add-to-cart-button {
          padding: 0.5rem 1rem;
          display: block;
          margin: 0 auto;
          margin-bottom: 2rem;
          font-size: 1rem;
          border: none;
          border-radius: 10px;
          background-color: black;
          color: $white;
          cursor: pointer;
          transition: 0.3s;
          font-family: 'Montserrat', sans-serif;

          &.disabled {
            background-color: $gray;
            cursor: not-allowed;
          }

          &:hover {
            background-color: $lightgray;
            color: $black;
            transform: scale(1.1);
            box-shadow: $shadow;
          }
        }

        .success-message {
          margin-top: 2rem;
          font-size: 1rem;
          color: $gray;
          font-family: 'Montserrat', sans-serif;
          text-align: center;
        }
      }
    }
  }

  .image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;

    .image-popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .image-popup-image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .image-popup-close {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 1.5rem;
      color: $white;
      cursor: pointer;
      font-family: 'Montserrat', sans-serif;
    }
  }
}

.return-button {
    display: block;
    max-width: 70px;
    margin: auto;
    text-decoration: none;
    color: $black;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    background-color: white;
    border-radius: 10px;
    box-shadow: $shadow;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: 0.3s;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 70px;

    &:hover {
      color: $gray;
      transform: scale(1.1);
    }
  }

