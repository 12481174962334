@import 'variables';

.shop-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 70px;
}

.item-container {
  position: relative;
  width: calc(33.33% - 20px);
  border-radius: 10px;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0 7px 10px rgba(65, 65, 65, 0.5);
  transition: all 0.3s ease-in-out;
  margin-top: 70px;

  .aspect-ratio-box {
    position: relative;
    width: 100%;
    height: 300px; // Fixed height for example
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    display: flex;

    img {
      flex-shrink: 0;
      width: 100%; 
      height: 100%;
      object-fit: contain;
      scroll-snap-align: start;
    }

    @media (min-width: 769px) {
      img {
        position: absolute;
        transition: opacity 0.5s;
      }
  
      .left {
        opacity: 1;
      }
  
      .right {
        opacity: 0;
      }
  
      &.show-right .left {
        opacity: 0;
      }
  
      &.show-right .right {
        opacity: 1;
      }
    }
  }
  
  .item-price {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin-top: 2rem;
  }

  .item-name {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 3rem;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 7px 15px rgba(65, 65, 65, 0.7);
    cursor: pointer;
  }
}

.item-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

@media (max-width: 768px) {
  .item-container {
    width: 100%;

    .aspect-ratio-box {
      height: 360px;
    }
  }

  .shop-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .aspect-ratio-box {
    img {
      width: 100%;
    }
  }

  .aspect-ratio-box::-webkit-scrollbar {
    height: 3px;
  }
  
  .aspect-ratio-box::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }
  
  .aspect-ratio-box::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
  
  .aspect-ratio-box::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
}
