@import 'variables';

.splash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $gray;
  transition: opacity 0.5s ease-in-out;
  font-family: 'Montserrat', sans-serif;
}

.loading-text {
  font-size: 32px;
  font-weight: bold;
  animation: fade-out 1.5s ease-in-out forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .loading-text {
    margin-top: -10rem;
  }
}
