@import 'variables';

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background-color: $white;
}

.navDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100px;
  background-color: $white;
  padding-right: 4rem;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.navDiv.show {
  opacity: 1;
}

.navDiv.hidden {
  opacity: 1;
}

.navLink {
  text-decoration: none;
}

.navButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.3s ease-in-out;
}

.navButton:hover {
  svg,
  .navName {
    color: $gray;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
}

.navName {
  color: $black;
  font-size: 1rem;
  font-weight: 600;
  padding: 0 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.brandName {
  color: $black;
  font-size: 2rem;
  font-weight: 600;
  padding: 0 5rem;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.brandName:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.logo {
  width: 40px;
  height: auto;
  padding-left: 4rem;
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  color: $black;
  cursor: pointer;
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  color: $gray;
  transition: color 0.3s ease;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.hamburger:active {
  color:$black;
}

.navButtons {
  display: flex;
  align-items: center;
}

.navButtons.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px; /* Adjust the top position to cover the entire home screen */
  left: 0;
  right: 0;
  height: calc(100vh - 80px); /* Adjust the height to cover the entire home screen */
  background-color: $white;
  z-index: 1;
}

@media (max-width: 768px) {
  .navButtons {
    display: none;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }

  .brandName {
    margin-top: 5rem;
    margin-left: -1rem;
  }

  .navButtons.active {
    display: flex;
    color: $white;
  }

  .navButton {
    margin-bottom: 3rem;
  }  

  .hamburger {
    display: block;
  }

  .navName {
    font-size: 1.5rem;
  }

  .hamburger:hover {
    color: $gray;
    transition: color 0.3s ease;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 550px) {
   .hamburger:hover {
    color: $black;
  }

  .navButton {
    margin-bottom: 0;
  }

  .brandName {
    margin-top: 0;
    margin-bottom: 0;
  }
}