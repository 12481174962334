$orange: #FD7816;
$black: black;
$white: white;
$gray: gray;
$lightgray: #d2d1d1;
$darkgray: #191919;
$teal: #30D5C8; // this is a teal color, replace it with the color you prefer


$shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);

body {
    background-color: $lightgray;
}