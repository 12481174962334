@import 'variables';

.home-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../images/UBSCXRBackground.jpg');
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  background-size: 100%; /* Adjusted to 75% to make it 25% smaller */

  &.fade-in {
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
    z-index: 1;
  }
}

.home-button {
  border: none;
  border-radius: 5px;
  padding: 20px 30px;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: $white;
  color: $black;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  box-shadow: 0 7px 10px rgba(65, 65, 65, 0.5);
  position: relative;
  z-index: 2;
}

.home-button:hover {
  color: $gray;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .home-button {
    margin-top: -3rem;
    z-index: 1;
  }

  .home-container {
    height: 100vh;
    z-index: 0;
  }
}
