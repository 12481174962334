@import 'variables';

body {
  background-color: $lightgray;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: $black;
  padding: 20px;

  h1,
  h2 {
    margin-bottom: 2.5rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: $darkgray;
    text-shadow: 0 3px 10px rgba(65, 65, 65, 0.5);
    font-size: 2rem;
  }

  .contact-form,
  .success-message {
    width: 90%;
    max-width: 600px;
    transition: all 0.5s ease-in-out;

    @media (min-width: 601px) and (max-width: 1024px) {
      max-width: 600px;
    }

    @media (max-width: 600px) {
      max-width: 90%;
    }

    input[type='text'],
    input[type='email'],
    textarea {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 30px;
      font-size: 1rem;
      border: 0px;
      border-radius: 5px;
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      box-shadow: 0 7px 10px rgba(65, 65, 65, 0.5);
      transition: all 0.3s ease-in-out;

      &:focus {
        border-color: $black;
        outline: none;
      }

      &:hover {
        transform: scale(1.02);
      }
    }

    textarea {
      height: 200px;
      resize: none;
      font-family: 'Montserrat', sans-serif;
    }

    .submit-button-container {
      font-size: 1.3rem;
      font-weight: 600;
      text-align: center;
      color: $black;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 2.5rem;
      margin-bottom: 3rem;

      input[type='submit'] {
        border: none;
        border-radius: 5px;
        padding: 15px 25px;
        font-size: 1.3rem;
        font-weight: 600;
        background-color: $white;
        color: $black;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        font-family: 'Montserrat', sans-serif;
        box-shadow: 0 7px 10px rgba(65, 65, 65, 0.5);

        &:hover {
            color: $gray;
            transform: scale(1.1);
        }
      }
    }
  }

  .success-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }
}

@media (max-width: 768px) {
  .contact-container {
    min-height: 0;
  }
}
